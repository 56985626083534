<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="스케줄 등록"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 일시</th>
                                <td>
                                    <carrot-date-picker v-model="add.vdate" class="dp-inblock mr-5"></carrot-date-picker>
                                    <carrot-time-select v-model="add.vtime" class="ml-10"></carrot-time-select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 목적</th>
                                <td>
                                    <label v-for="(irow, i) in add.rtype_list" :key="i"><input v-if="irow.code !='마케팅 컨택/제안'" type="radio"  v-model="add.reason" :value="irow.code"><span v-if="irow.code !='마케팅 컨택/제안'" class="ml-5 mr-20"> {{ irow.name}}</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사</th>
                                <td>
                                    <carrot-company v-model="add.company" class="w-400px"></carrot-company>
                                    <span class="ml-10">* 입력 시 나오는 고객사명에서 선택해주세요. </span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 상세내용</th>
                                <td>
                                    <textarea v-model.trim="add.contents" class="w-100per h-100px"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기타사항<br>(장소 등)</th>
                                <td>
                                    <textarea v-model.trim="add.etc_memo" class="w-100per h-100px"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> Self Driven</th>
                                <td>
                                    <label v-for="(irow, i) in add.dtype_list" :key="i">
                                        <input type="radio"  v-model="add.driven" :value="irow.code">
                                        <span class="ml-5 mr-20"> {{ irow.name }}</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotTimeSelect from '@/components/common/CarrotTimeSelect.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'


export default {
    layout:"customerManagement",
    components: {
        CarrotDatePicker,
        CarrotTimeSelect,
        CarrotCompany
    },
    setup() {
        const router = new useRouter();
        const toast = useToast();

        const add = reactive({
            rtype_list : [],
            dtype_list : [],

            vdate    : "",
            vtime    : "",
            reason   : [],
            company  : 0,
            contents : "",
            etc_memo : "",
            driven : "Self Driven 2.0",

            doCancel : () => {
                Swal.fire({
                    title : "스케줄 등록",
                    text  : "입력하신 내용이 사라집니다. 등록을 취소하시겠습니까?"
                }).then(() => {
                    router.go(-1);
                });
            },

            doSearch : () => {
                axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        add.rtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });

                axios.get("/rest/customermgr/getDtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        add.dtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    vdate    : add.vdate,
                    vtime    : add.vtime,
                    reason   : add.reason,
                    company  : add.company,
                    contents : add.contents,
                    etc_memo : add.etc_memo,
                    driven   : add.driven
                };

                if( !params.vdate || !params.vtime || !params.reason.length || !params.company || !params.contents || !params.etc_memo || !params.driven){
                    toast.error("필수항목을 입력해 주세요.");
                    return;
                }

                axios.post("/rest/customermgr/addSchedule", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title : "등록되었습니다."
                        }).then(() => {
                            let ss = sessionStorage.getItem("scheduleVisitWeekly");
                            if( ss ){
                                ss = JSON.parse(ss);
                                let bbs = {
                                    page : 1, sdate : "", edate : "", state : "", office : 0, team : 0
                                };
                                bbs.state  = ss.state?ss.state:"";
                                bbs.office = ss.office>0?ss.office:0;
                                bbs.team   = ss.team>0?ss.team:0;

                                let nd = new Date(add.vdate);
                                let td = nd.getDate() - nd.getDay();
                                let fd = new Date(nd.setDate(td));
                                let ld = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+6);
                                bbs.sdate = [fd.getFullYear(), fd.getMonth()>8?fd.getMonth()+1:'0'+(fd.getMonth()+1), fd.getDate()>9?fd.getDate():'0'+fd.getDate()].join('-');
                                bbs.edate = [ld.getFullYear(), ld.getMonth()>8?ld.getMonth()+1:'0'+(ld.getMonth()+1), ld.getDate()>9?ld.getDate():'0'+ld.getDate()].join('-');

                                sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
                                    sdate  : bbs.sdate,
                                    edate  : bbs.edate,
                                    state  : bbs.state,
                                    office : bbs.office,
                                    team   : bbs.team
                                }));
                            }

                            router.push({
                                name : 'customerManagement-scheduleVisitWeekly'
                            });

                        })
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            add.doSearch();
        });

        return {add};
    }
}
</script>

<style lang="scss" scoped>
</style>